import React from "react";
import * as IoIcons from "react-icons/io";

export const SidebarData = [


{
	title: "API Status",
	path: "/status",
	id:"1",
	icon: <IoIcons.IoIosPaper className="icon-big" style={{ color: '#666', size: '50px' }}/>,
},

{
	title: "API Documentation",
	path: "/document",
	id:"2",
	icon: <IoIcons.IoIosPaper style={{ color: '#666', size: '50px' }}/>,
},
];
