import "./App.css";
import Sidebar from "./components/Sidebar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { My404Component } from "./pages/My404Component";
import Status from "./pages/StatusDashboard";
import Support from "./pages/Support";

function App() {
return (
	<Router>
	<Sidebar />
	<div className="main_body" style={{display:"flex",float:"right", width:"81%", height:"100%"}}>
	<Switch>
		<Route path="/status" exact component={Status} />
		<Route path="/document" exact component={Support} />
		<Route path="/" exact component={Status} />
		<Route path="*" exact component={My404Component} />
		

	</Switch>
	</div>
	</Router>
	
);
}

export default App;
