
import React,  {Component}  from 'react'

import DataTable from 'react-data-table-component'
require('dotenv').config()
var log = require('loglevel');
const customStyles = {
  Table:{
    style:{
      height:"auto"
    }
  },
  rows: {
    style: {
      border: "0px"
    }
  },
  headCells: {
    style: {
      height: "36px",
      border:"0px"
    },
  },
};

//export default Status;
export default class Status extends Component {
  constructor(props) {
    super(props)
   
    
    this.state = {
      selectedCustomer: 1,
      selectedFile:'',
      columns: [
        {
          name: 'API NAME',
          selector: 'name',
          sortable: true,
        },
        {
          name: 'STATUS',
          selector: 'status',
          sortable: true,
        }
        ],
      data:[],
      period:0,
      showDatatable:false
      
    }
  }
  componentDidMount() {
    this.fetchStatus()
    const interval = process.env.REACT_APP_API_INTERVAL
    this.interval = setInterval(this.fetchStatus, interval);
  }
  async fetchStatus() {
    try {
      
      const api_path = process.env.REACT_APP_API_URL
      const subscription_key_v1 = process.env.REACT_APP_API_KEY_V1
      const subscription_key_v2 = process.env.REACT_APP_API_KEY_V2
      const authtoken = process.env.REACT_APP_API_AUTH_TOKEN
      const res1 = await fetch(api_path+'v1/health?subscription-key='+subscription_key_v1, {headers: {'Authorization':authtoken}});
      const v1_statuscode = await res1.status

      const res2 = await fetch(api_path+'v2/health?subscription-key='+subscription_key_v2, {headers: {'Authorization':authtoken}});
      const v2_statuscode = await res2.status

      this.processData(v1_statuscode, v2_statuscode)
   } catch (e) {
      log.warn("Error while fetching status due to - "+e);
   }
 }
 componentWillUnmount() {
  // Clear the interval right before component unmount
  clearInterval(this.interval);
  }
  processData(v1_statuscode, v2_statuscode) {
    let status_html = <div style={{backgroundColor:"#f8c82e",color:"#ffffff",padding:"4px 11px",border:"1px solid #e5e5e5",fontWeight:"400"}}>IN ACTIVE</div>
    
    if (v1_statuscode === 200) {
      status_html = <div style={{backgroundColor:"rgb(100, 234, 145)",color:"#ffffff",padding:"4px 11px",border:"1px solid #e5e5e5",fontWeight:"400"}}>ACTIVE</div>
    }
      const data = [
      {
        "name": "/v1/getubScriptionKey",
        "id": 2,
        "status": status_html
      },  
      {
        "name": "/v1/health",
        "id": 1,
        "status": status_html
      },
      {
        "name": "/v1/generateToken",
        "id": 3,
        "status": status_html
      },
      {
        "name": "/v1/machineDetails",
        "id": 4,
        "status": status_html
      },
      {
        "name": "/v1/problemCodes",
        "id": 5,
        "status": status_html
      },
      {
        "name": "/v1/submitServiceRequest",
        "id": 6,
        "status": status_html
      },
      {
        "name": "/v1/requestStatus",
        "id": 7,
        "status": status_html
      },
      {
        "name": "/v1/requests",
        "id": 8,
        "status": status_html
      },
      {
        "name": "",
        "id": 9,
        "status": ""
      },
      ];
      this.setState({data})

      let status_html_2 = <div style={{backgroundColor:"#f8c82e",color:"#ffffff",padding:"4px 11px",border:"1px solid #e5e5e5",fontWeight:"400"}}>IN ACTIVE</div>
    
      if (v2_statuscode === 200) {
        status_html_2 = <div style={{backgroundColor:"rgb(100, 234, 145)",color:"#ffffff",padding:"4px 11px",border:"1px solid #e5e5e5",fontWeight:"400"}}>ACTIVE</div>
      }
      const v2_apis = [
        {
          "name": "/v2/subscriptionkey",
          "id": 10,
          "status": status_html_2
        },
        {
          "name": "/v2/health",
          "id": 11,
          "status": status_html_2
        },
        {
          "name": "/v2/token",
          "id": 12,
          "status": status_html_2
        },
        {
          "name": "/v2/machines/{machineId}",
          "id": 13,
          "status": status_html_2
        },
        {
          "name": "/v2/machines/{machineType}/problemcodes",
          "id": 14,
          "status": status_html_2
        },
        {
          "name": "/v2/servicerequest",
          "id": 15,
          "status": status_html_2
        },
        {
          "name": "/v2/servicerequests/{requestId}",
          "id": 16,
          "status": status_html_2
        },
        {
          "name": "/v2/servicerequests",
          "id": 17,
          "status": status_html_2
        }
      ]
      this.setState(prevState => ({
        data: [
            ...prevState.data, 
            ...v2_apis
        ]
    }))
      
  }
render() {
  return (
    <div style={{width:"100%", margin:"39px", height:"100%"}}>
    <DataTable
              highlightOnHover
              columns={this.state.columns}
              customStyles={customStyles}
              data={this.state.data}/>
    </div>
   )
}
};