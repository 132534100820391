import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import { SidebarData } from "./SidebarData";
import { IconContext } from "react-icons/lib";
import logo from "./logo.png"
const Nav = styled.div`
  background: #ffffff;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow:4px 4px 40px 0 rgb(227 211 211 / 5%)
`;
  
const NavIcon = styled(Link)`
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SidebarLink = styled(Link)`
display: flex;
color: #666;
justify-content: space-between;
align-items: center;
padding: 20px;
list-style: none;
height: 43px;
text-decoration: none;

&:hover {
	background: #e6f7ff;
	border-right: 4px solid #1890ff;
	cursor: pointer;
}
`;

const SidebarLabel = styled.span`
margin-left: 16px;
`;


const SidebarNav = styled.nav`
  background: #ffffff;
  box-shadow: rgb(24 144 255 / 10%) 0 0 28px 0;
  width: 256px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
  font-size:16px
`;
  
const SidebarWrap = styled.div`
  width: 100%;
`;
  
const Sidebar = () => {
  const [sidebar, setSidebar] = useState(true);
  
  const showSidebar = () => setSidebar(!sidebar);
  
  const [activeIndex, setActiveIndex] = useState(() => { 
    const initialIndex = 
      window.location.pathname === '/' ? 1 
        : window.location.pathname === '/status' ? 1 
          : window.location. pathname === '/document' ? 2 
            : 1; 
    return initialIndex; 
  });
  
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <NavIcon to="#">
            <FaIcons.FaBars onClick={showSidebar} />
          </NavIcon>
           
      
          <h1
            style={{ textAlign: "center", 
                     marginLeft: "200px", 
                     color: "green" }}
          >
            
          </h1>
        </Nav>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon to="#">
            <div >
            <img alt="logo" src={logo} style={{width:"116px"}}/>
            </div>
            </NavIcon>
            <div className="menuContainer___2WW7T" style={{padding:"24px 0px",height:"calc(100vh - 120px)",overflowX: "hidden",flex: "1 1"}}>

            <SidebarLink to={SidebarData[0].path} className={activeIndex === 1 ? "activeLink": ""} onClick={() => setActiveIndex(1)}>
            <div>
            {SidebarData[0].icon}
            <SidebarLabel>{SidebarData[0].title}</SidebarLabel>
            </div>
            </SidebarLink>

            <SidebarLink to={SidebarData[1].path} className={activeIndex === 2? "activeLink": ""} onClick={() => setActiveIndex(2)}>
            <div>
            {SidebarData[1].icon}
            <SidebarLabel>{SidebarData[1].title}</SidebarLabel>
            </div>
            </SidebarLink>

            </div>
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};
  
export default Sidebar;