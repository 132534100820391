import React, {PureComponent} from "react";
import SwaggerUI from "swagger-ui-react";
import Cookies from 'universal-cookie';
import "swagger-ui-react/swagger-ui.css";

const cookies = new Cookies();
require('dotenv').config()
const env = process.env.REACT_APP_API_ENVIRONMENT

class Support extends PureComponent {
	
	render() {
		const api_path = process.env.REACT_APP_API_URL
		const version = process.env.REACT_APP_BASE_VERSION
		 
		let definition = require('./swaggerdoc_v1.json');
		if (env === 'production') {
			definition = require('./swaggerdoc-prod_v1.json');
		}
		
		return (
			<div className="swagger_test" style={{width:"100%", margin:"39px", padding:"24px",background:"white",  height:"calc(100% - 72px)"}}>
				<SwaggerUI spec={definition} requestInterceptor={(request) => {
request.headers['X-XSRF-TOKEN'] = cookies.get("XSRF-TOKEN")
}}/>
			</div>
			)
	}
  }

export default Support;
